import React from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom";
import { auth } from './Support/firebase'

const Header = ({ data }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  if (!data) return null;
  const handleButtonClick = () => {
    user ? (
      navigate('/dashboard')
    ) : (
      navigate("/votenow")
    )
  };

  return (
    <header id="home">
      <ParticlesBg type="circle" bg={true} />

      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#home">
              Home
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="#about">
              About
            </a>
          </li>

          <li>
            <a className="smoothscroll" href="/votenow">
              Vote Now
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <Fade bottom>
            <h1 className="responsive-headline">MyVoteHub</h1>
          </Fade>
          <Fade bottom duration={1200}>
            <h3>Empower your community's voice with our user-friendly voting website tailored for Indian communities. Experience seamless and secure elections, fostering democratic participation and civic engagement. Make your opinions count as we pave the way for transparent and accessible community decision-making.</h3>
          </Fade>
          <hr />
          <Fade bottom duration={2000}>
            <ul className="social">
              <button onClick={handleButtonClick} className="button btn project-btn">
                <i className="fa fa-book"></i>Vote Now
              </button>
              <a href="#about" className="button btn github-btn">
                <i className="fa fa-users"></i>More About Us
              </a>
            </ul>
          </Fade>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};

export default Header;