import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import HomePage from './Home';
import AuthenticatePage from './Components/Login';
import Dashboard from './Components/Dashboard';
import ProtectedRoute from './Components/Support/ProtectedRoutes';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/votenow" element={<AuthenticatePage />} />
        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route path="" element={<Dashboard />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;