import React, { useState } from 'react';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';
import { BiHome, BiHelpCircle, BiLogOut } from 'react-icons/bi';
import logo from './logo.png';
import { auth } from './Support/firebase'

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogout = () => {
    // Add your logout logic here
    navigate('/');
    auth.signOut();
    setShowLogoutConfirmation(false);
  };

  const cancelLogout = () => {
    // Reset the state to hide the confirmation popup
    setShowLogoutConfirmation(false);
  };

  return (
    <div className="dashboard">
      <aside className="sidebar">
        <img src={logo} alt="sus" style={{ alignSelf: 'center' }} />
        <ul>
          <li onClick={() => changePage('home')}>
            <BiHome /> Home
          </li>
          <li onClick={() => changePage('page1')}>
            <BiLogOut /> Vote Now
          </li>
          <li onClick={() => changePage('page2')}>
            <BiHelpCircle /> FAQ's
          </li>
        </ul>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
        {showLogoutConfirmation && (
          <div className="popup">
            <p>Are you sure you want to log out?</p>
            <button className="confirm" onClick={confirmLogout}>
              Yes
            </button>
            <button className="cancel" onClick={cancelLogout}>
              No
            </button>
          </div>
        )}
      </aside>
      <div className="content">
        <nav className="navbar">
          <h2>MyVoteHub</h2>
        </nav>
        <div className="main-content">
          {currentPage === 'home' && <HomeContent />}
          {currentPage === 'page1' && <Page1Content />}
          {currentPage === 'page2' && <Page2Content />}
        </div>
      </div>
    </div>
  );
};

const HomeContent = () => (
  <div>
    <h2>Home Page</h2>
    <p>Welcome to the home page!</p>
  </div>
);

const Page1Content = () => (
  <div>
    <h2>Vote Now</h2>
    <p>Under Development Coming Soon!</p>
  </div>
);

const Page2Content = () => (
  <div>
    <h2>FAQ's & Help</h2>
    <p>Under Development Coming Soon!</p>
  </div>
);

export default Dashboard;