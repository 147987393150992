import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBHUUUsXPjnMsSWYiYCmGeuPk15Z2OI8CA",
    authDomain: "myvotehub.firebaseapp.com",
    projectId: "myvotehub",
    storageBucket: "myvotehub.appspot.com",
    messagingSenderId: "161962033894",
    appId: "1:161962033894:web:c4fe7ab6d82f00e8cd3127",
    measurementId: "G-SVCZFZM14P"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)