import React, { useState, useEffect } from 'react';
import './login.css';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { auth } from './Support/firebase'
import { useNavigate } from "react-router-dom";

const AuthenticatePage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpTextBox, setOtpTextBox] = useState(false);
    const [otp, setOtp] = useState('');
    const [confirmationResult, setConfirmationResult] = useState('');
    const [otpSent, setOtpSent] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
            'size': 'invisible',
            "callback": (response) => {
                console.log("Verified");
            },
            'expired-callback': () => {
                console.log("Error");
            }
        });

        return () => {
            // Clean up the recaptchaVerifier
            window.recaptchaVerifier.clear();
        };
    }, []); // Empty dependency array, run only once on mount

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleOTPChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSendOtp = async () => {
        try {
            setOtpTextBox(!otpTextBox);
            const formattedPhoneNumber = '+91' + phoneNumber;
            const confirmation = await signInWithPhoneNumber(auth, formattedPhoneNumber, window.recaptchaVerifier)
            setConfirmationResult(confirmation);
            console.log("OTP SENT!!")
            setOtpSent(true);
        } catch (error) {
            console.error(error)
        }
    }

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        try {
            await confirmationResult.confirm(otp);
            setOtp('');
            setPhoneNumber('');
            console.log('Redirecing.....')
            navigate('/dashboard');
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container">
            <form className='login-form'>
                {!otpSent ? (
                    <div id="recaptcha-container"></div>
                ) : null}
                <h2>Login</h2>
                <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                />
                {otpTextBox &&
                    <input
                        type="text"
                        placeholder="One Time Password"
                        value={otp}
                        onChange={handleOTPChange}
                        required
                    />
                }
                <button type="submit" onClick={otpSent ? handleOTPSubmit : handleSendOtp} >{otpSent ? 'Verify' : 'Send OTP'}</button>
            </form>
        </div>
    );
}

export default AuthenticatePage;