import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="nine columns main-col">
              <h2>About Us</h2>

              <p>We are a team of 3 making a seamless, simple to use, website to make elections in huge communities hassle free. We aim on improving and leading democracy into the modren world of technology</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>MyVoteHub</span>
                    <br/>
                    <span>info@myvotehub.in</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
