import { Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';

const ProtectedRoutes = () => {
    const [user, loading] = useAuthState(auth);
    console.log(user);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        user ? (
            <Outlet />
        ) : (
            <Navigate to="/authenticate" replace />
        )
    );
};

export default ProtectedRoutes;